/**
 * Imports libraries of settings, mixins, functions, etc. that should be available
 * to all stylesheets. MUST NOT OUTPUT STYLES.
 *
 * @author    <corey@chatmandesign.com>
 * @since     3.0.0
 */
/**
 * Breakpoint range variables for the lock mixin.
 * Convert the breakpoints map into range variables like we had in ZF5. The
 * magic number at the end is the difference between the breakpoint we define in
 * $breakpoints and the actual max-width value spit out by the breakpoints()
 * mixin when we give it an "only" argument (to avoid overlap. See the ZF5 media
 *  query range variables for reference.)
 *
 * $small-range is a special snowflake. The whole purpose of the lock mixin is
 * to let you set a property to a fixed value up to a certain breakpoint, then
 * have that value increase in a linear fashion until you hit another
 * breakpoint, and then keep the value fixed again for the rest of the way. The
 * way the breakpoints are defined, the small range would be 0px - 640px. But
 * we're not planning on supporting devices smaller than 320px, so it's far more
 * useful for the small range to be 320px to 640px. That way, the $min-value you
 * pass to the lock mixin is anchored to the smallest device width we support,
 * and the $max-value is anchored to the breakpoint between small and medium
 * ranges.
 *
 * @author   <dustin@chatmandesign.com>
 */
/**
 * Sass Mixins
 *
 * @package   seedyjoints
 * @since     1.0
 */
/**
 * Extend element's background to left and right edges of viewport.
 *
 * @since    3.0.0
 * @author   <corey@chatmandesign.com>
 *
 * @param string $position  Any valid CSS position value, or NULL/FALSE to not set
 *                          position; but note the element must create a positioning
 *                          context for the bleed effect to work. Default: relative.
 * @param string $direction Direction the bleed will extend in -- left, right, both.
 *                          Deafult: both.
 */
/**
 * Lock (Fluid Type Mixin -- CD Remix)
 * Created 10/11/16 @ 02:19:30 PM
 *
 * Original from here:
 * http://madebymike.com.au/writing/precise-control-responsive-typography/
 *
 * This is a modified version of the above mixin that takes a single SCSS list
 * instead of two variables for min and max VW. This makes it easier to use with
 * the existing Foundation range variables in _settings.scss. You can of course
 * still pass a plain list e.g. "(20rem, 40rem)" as the argument for the range
 * parameter if you need a custom range.
 *
 * @param      $properties A space separated list of CSS properties
 * @param      $vw-range A SCSS list defining a viewport width range like
 *             "(20em, 40em)".
 * @param      $min-value A minimum property value in px/em/rem
 * @param      $max-value A maximum property value in px/em/rem
 * @author     Chatman Design / Dustin Paluch <dustin@chatmandesign.com>
 */
/**
 * List items inline, separated by pipes by default.
 *
 * @since    3.0.0
 * @author   <corey@chatmandesign.com>
 */
/**
 * Transition property with vendor prefixes (DEPRECATED)
 *
 * DEPRECATED now that we use autoprefixer. Just use the standard CSS property.
 *
 * I totally rewrote this to be cleaner and easier to use. You'll need to be using
 * Sass 3.2+ for these to work. Thanks to @anthonyshort for the inspiration on these.
 *
 * USAGE: @include transition(<property> <time> <easing>);
 */
/**
 * CSS3 Gradients with vendor prefixes
 *
 * Be careful with these since they can really slow down your CSS. Don't overdo it.
 */
/* @include css-gradient(#dfdfdf,#f8f8f8); */
/**
 * Foundation for Sites
 * Version 6.6.3
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.search-display {
  margin-left: -1rem;
}
.search-display .search-display__icon {
  left: 0;
  top: -0.0625rem;
  width: 2.25rem;
  height: 2.25rem;
  position: relative;
  transform: scaleX(-1);
  background-color: #fefefe;
  background-size: 55%;
  border-radius: 50%;
  cursor: pointer;
  border: 0;
  margin-left: 1.5rem;
  opacity: 1;
  transition: all 0.5s ease-in-out;
}
.search-display .search-display__icon::before, .search-display .search-display__icon::after {
  transition: all 0.5s ease-in-out;
  background-size: 65%;
  background-repeat: no-repeat;
  background-position: center;
}
.search-display .search-display__icon::before {
  content: "";
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/wp-content/themes/custom/global/images/icons/ss-custom/search.svg");
}
.search-display .search-display__icon::after {
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/wp-content/themes/custom/global/images/icons/ss-custom/times-symbol.svg");
}
.search-display .search-display__icon.search-open::before {
  opacity: 0;
}
.search-display .search-display__icon.search-open::after {
  opacity: 1;
}
.search-display .search-display__icon:hover, .search-display .search-display__icon:focus {
  border-radius: 0%;
}
@media print, screen and (min-width: 40em) {
  .search-display {
    margin-left: 0;
  }
}

.search-display__form {
  background: rgba(43, 138, 199, 0.95);
  position: fixed;
  top: 0;
  width: 100%;
  padding: 6rem 0;
  margin-top: 4.5rem;
  transform: translateY(0%);
  opacity: 0;
  display: none;
  z-index: 1;
  transform: translateY(50%);
  transition: all 0.5s ease-in-out;
}
@supports (backdrop-filter: blur(1px)) {
  .search-display__form {
    background: rgba(43, 138, 199, 0.75);
    backdrop-filter: blur(4px);
  }
}
.search-display__form .content {
  max-width: 81.25rem;
  margin: auto;
}
.search-display__form .content .search-form {
  display: flex;
  justify-content: center;
}
.search-display__form .content .search-form label {
  width: 60%;
  margin-right: 1rem;
}
.search-display__form .content .search-form label .search-field {
  margin-bottom: 0;
  background: rgba(255, 255, 255, 0);
  color: #fefefe;
  box-shadow: none;
  border: unset;
  border-bottom: 1px solid #fefefe;
  font-size: 1.5rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 0.5rem;
}
.search-display__form .content .search-form label .search-field::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  border-radius: 50em;
  background: url("/wp-content/themes/custom/global/images/icons/ss-custom/times-circle-white.svg") no-repeat 50% 50%;
  background-size: contain;
  cursor: pointer;
}
.search-display__form .content .search-form label .search-field::placeholder {
  color: rgba(254, 254, 254, 0.75);
}
.search-display__form .content .search-submit {
  color: #fefefe;
  cursor: pointer;
  background: rgba(255, 255, 255, 0);
  transition: all 0.5s ease-in-out;
  border: solid 2px #fefefe;
  outline: solid 1px rgba(255, 255, 255, 0);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 1.25rem;
}
.search-display__form .content .search-submit:hover, .search-display__form .content .search-submit:focus {
  background: #0a0a0a;
  outline-color: #fefefe;
}
.search-display__form.slide-in {
  transform: translateY(0%);
  opacity: 1;
}
.search-display__form.compact {
  transform: translateY(-2rem) translateY(50%);
}
.search-display__form.compact.slide-in {
  transform: translateY(-2rem) translateY(0%);
}
@media print, screen and (min-width: 40em) {
  .search-display__form {
    margin-top: 5.5rem;
  }
}