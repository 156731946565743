@import '../../global/styles/lib';
$submit-height: 2.25rem;
$icon-scale: 55%;
$search-form-z: 20;
$search-form-overflow-z: 25;

$search-icon: '/wp-content/themes/custom/global/images/icons/ss-custom/search.svg';
$search-icon-close: '/wp-content/themes/custom/global/images/icons/ss-custom/times-symbol.svg';
$search-icon-clear: '/wp-content/themes/custom/global/images/icons/ss-custom/times-circle-white.svg';

$search-translate-down: translateY(50%);

.search-display {
    margin-left: -1rem;

    .search-display__icon {
        left: 0;
        top: rem-calc(-1);
        width: $submit-height;
        height: $submit-height;
        position: relative;
        transform: scaleX(-1);
        background-color: $white;
        background-size: $icon-scale;
        border-radius: 50%;
        cursor: pointer;
        border: 0;
        margin-left: 1.5rem;
        opacity: 1;
        transition: all $header-transition;

        &::before,
        &::after {
            transition: all $header-transition;
            background-size: 65%;
            background-repeat: no-repeat;
            background-position: center;
        } // &::before, &::after

        &::before {
            content: "";
            opacity: 1;
            @include absolute-full;
            background-image: url($search-icon);
        } // &::before

        &::after {
            content: "";
            opacity: 0;
            @include absolute-full;
            background-image: url($search-icon-close);
        } // &::after

        &.search-open {
            &::before {
                opacity: 0;
            } // &::before 

            &::after {
                opacity: 1;
            } // &::before 
        }

        #{$hovfoc} {
            border-radius: 0%;
        } // &:hover, &:focus

    } // .search-display__icon    

    @include breakpoint(medium up) {
        margin-left: 0;
    } // medium up

} // .search-display

.search-display__form {
    @include header-background-color-blur;
    position: fixed;
    top: 0;
    width: 100%;
    padding: 6rem 0;
    margin-top: 4.5rem; // header height
    transform: translateY(0%);
    opacity: 0;
    display: none;
    z-index: 1;
    transform: $search-translate-down;
    transition: all $header-transition;

    .content {
        max-width: $row-max-width;
        margin: auto;
        .search-form {
            display: flex;
            justify-content: center;
            label {
                width: 60%;
                margin-right: 1rem;
                .search-field {
                    margin-bottom: 0;
                    background: $transparent;
                    color: $white;
                    box-shadow: none;
                    border: unset;
                    border-bottom: 1px solid $white;
                    font-size: 1.5rem;
                    padding-top: 1.25rem;
                    padding-bottom: 1.25rem;
                    padding-left: 0.5rem;
                    
                    &::-webkit-search-cancel-button{
                        -webkit-appearance: none;
                        height: 1em;
                        width: 1em;
                        border-radius: 50em;
                        background: url($search-icon-clear) no-repeat 50% 50%;
                        background-size: contain;
                        cursor: pointer;
                    } // &::-webkit-search-cancel-button

                    &::placeholder {
                        color: rgba($white, 0.75);
                    } // &::placeholder

                } // .search-field

            } // label

        } // .search-form

        .search-submit {
            color: $white;
            cursor: pointer;
            background: rgba(255, 255, 255, 0);
            transition: all $header-transition;
            border: solid 2px $white;
            outline: solid 1px $transparent;
            padding-left: 0.75rem;
            padding-right: 0.75rem;
            font-size: 1.25rem;

            &:hover,
            &:focus {
                background: rgba($black, 1);
                outline-color: $white;
            } // &:hover, &:focus

        } // .search-submit

    } // .content

    &.slide-in {
        transform: translateY(0%);
        opacity: 1;
    } // &.slide-in

    &.compact {
        transform: $header-translate-up $search-translate-down;

        &.slide-in {
            transform: $header-translate-up translateY(0%);
        } // &.slide-in
    } // &.compact

    @include breakpoint(medium up) {
        margin-top: 5.5rem; // header height
    } // medium up

} // .search-display__form
